import Box from '@material-ui/core/Box';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import { useStoreState } from '~/store/hooks';
import {
  getExpiresIn,
  getPaperworkStatus,
  getRecertificationColorByDate,
} from '~/ui/pages/Clients/reusable/PersonalDetails/helpers';
import formatClientAddress from '~/utils/dataFormat/formatClientAddress';
import { isoToLocal } from '~/utils/date/date';
import getRiskAssessmentLabel from '../../../../pages/MedicationLogistics/helpers/getRiskAssessmentLabel';

import StatusHistoryItem from '~/ui/pages/Clients/reusable/Status/StatusHistoryItem';
import Avatar from '~/ui/components/common/Avatar';

import { VIEW_CLIENT } from '~/ui/constants/paths';
import { suicideRiskColor } from '~/ui/constants/suicideRisks';
import { IClientDetailed } from '~/services/api/client/types';
import { IClientDetailsStore } from '~/store/clientDetails/types';
import { generalDateDotted } from '~/ui/constants/dateFormat';

import clientInfoIcon from '~/ui/assets/images/clientInfo.svg';
import styles from '../ClientInfo.module.scss';

interface IMainInfoProps {
  collapsed: boolean;
  onVisitsPage: boolean;
}

const MainInfo = ({ collapsed, onVisitsPage }: IMainInfoProps): ReactElement => {
  const { current } = useStoreState(state => state.client);

  const {
    photo,
    medicaId,
    address,
    primaryDiagnosis,
    firstName = '',
    lastName = '',
    suicideRisk,
    aot,
    riskAssessment,
    gender,
    dateOfBirth,
    medicaIdRecertificationDate,
    paperworkStatus,
    status,
  } = (current as IClientDetailed & IClientDetailsStore) || {};

  const clientAddress = address ? formatClientAddress(address) : null;

  const formattedRecertification = medicaIdRecertificationDate
    ? isoToLocal(medicaIdRecertificationDate, 'MM/yyyy')
    : null;

  const color = suicideRiskColor[suicideRisk];

  const CustomLink = ({ children, red }: { children: ReactNode; red?: boolean }) => (
    <Link
      className={classNames(styles.link, { [styles.red]: red })}
      to={VIEW_CLIENT.replace(':clientId', String(current?.id)).replace(
        ':actTeamId',
        String(current?.actTeam.id),
      )}
      state={{ actTeamId: String(current?.actTeam.id) }}
    >
      {children}
    </Link>
  );

  const medicaIdColor = getRecertificationColorByDate(medicaIdRecertificationDate, paperworkStatus);

  return (
    <Box className={classNames(styles.row, styles.marginRight, styles.wide)}>
      {collapsed && (
        <>
          <img className={styles.marginRight} src={clientInfoIcon} alt="client info" />
          <CustomLink red>
            <span className={styles.name}>{`${firstName} ${lastName}`}</span>
          </CustomLink>
        </>
      )}
      <Avatar url={photo} className={classNames(styles.avatar, { [styles.hidden]: collapsed })} />
      {!collapsed && current && (
        <Box className={styles.general}>
          <div style={{ color }} className={styles.name}>
            <CustomLink>
              <span
                className={classNames(styles.black, styles.marginRight)}
              >{`${firstName} ${lastName}`}</span>
            </CustomLink>{' '}
            {!!riskAssessment && (
              <span className={styles.black}>{getRiskAssessmentLabel(riskAssessment)}/</span>
            )}
            SR
            {aot ? <span className={styles.black}>/AOT</span> : ''}
          </div>
          <div className={styles.textItem}>
            {medicaId ? (
              <>
                <span
                  style={{
                    color: medicaIdColor,
                  }}
                >
                  {medicaId}
                </span>
                <span> / </span>
              </>
            ) : (
              ''
            )}
            {`${gender?.name || ''} / ${
              dateOfBirth ? format(new Date(dateOfBirth), generalDateDotted) : ''
            }`}
          </div>
          {primaryDiagnosis?.diagnosis.name && (
            <div
              className={styles.textItem}
            >{`Primary Dx: ${primaryDiagnosis?.diagnosis.name}`}</div>
          )}
          {!!clientAddress && <div className={styles.textItem}>{`Address: ${clientAddress}`}</div>}
          {onVisitsPage && !!formattedRecertification?.length && (
            <div
              className={styles.textItem}
              style={{
                color: medicaIdColor,
              }}
            >
              <span className={styles.textItem}>Medicaid Recertification Expiration:</span>{' '}
              {formattedRecertification}
              {medicaIdRecertificationDate ? getExpiresIn(medicaIdRecertificationDate) : ''}
              {!!true && (
                <>
                  <br />
                  <span className={styles.paperwork}>{getPaperworkStatus(paperworkStatus)}</span>
                </>
              )}
            </div>
          )}
          <StatusHistoryItem item={status} className={styles.clientStatus} withPrefixes />
        </Box>
      )}
    </Box>
  );
};

export default MainInfo;
